<template>
  <card class="shadow" body-classes="standard-card-body">
    <template slot="header" v-if="title">
      <h4 class="card-title text-capitalize">{{ title }}</h4>
    </template>

    <ValidationObserver ref="userForm">
      <ul class="list-group list-group-flush">
        <list-group-item-component
          v-bind:key="`list-keys-${index}`"
          v-for="(user, index) in localUsers"
          :label="getUserLabel(user)"
        >
          <template slot="value">
            <ValidationProvider
              name="salary"
              :vid="`salary-${index}`"
              rules="double:2|between:0,10000"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                maxlength="10"
                class="mb-0"
                v-model="user.salary"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              />
            </ValidationProvider>
          </template>
        </list-group-item-component>
      </ul>
    </ValidationObserver>
  </card>
</template>

<script>

import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "UsersSalaries",
  components: {ListGroupItemComponent},
  data() {
    return {
      localUsers: [],
    }
  },
  props: {
    usersList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.localUsers = this.$_.cloneDeep(this.usersList);
  },
  methods: {
    getUserLabel: function (user) {
      return user.firstname + " " + user.lastname
    },
    getUsersData: function () {
      return new Promise((resolve, reject) => {
        this.$refs.userForm.validate()
          .then((success) => {

            if (!success) {
              reject();
            } else {

              let data = this.localUsers.map((item) => {
                return {user_id: item.id, salary: "" + (item?.salary || 0)}
              });
              resolve(data);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped>

</style>
