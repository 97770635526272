var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',{staticClass:"shadow",attrs:{"body-classes":"standard-card-body"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.title))]),_c('base-button',{staticClass:"ml-auto text-capitalize",attrs:{"link":"","icon":"","size":"sm"},on:{"click":function($event){return _vm.openModal()}}},[_c('octo-icon',{attrs:{"icon":"add"}})],1)],1)]),_c('el-table',{attrs:{"stripe":"","data":_vm.dealThresholdsLocal}},[_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.type)+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('callcenter.threshold')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.threshold)+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('callcenter.value_over_threshold')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.value)+" ")])}}])}),_c('el-table-column',{attrs:{"align":"right","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{staticClass:"d-flex justify-content-end"},[_c('el-tooltip',{attrs:{"content":"Edit","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"mx-1",attrs:{"link":"","size":"sm","icon":""},on:{"click":function($event){return _vm.openModal($index)}}},[_c('octo-icon',{attrs:{"icon":"edit"}})],1)],1),_c('el-tooltip',{attrs:{"content":"Delete","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"mx-1",attrs:{"type":"danger","link":"","size":"sm","icon":""},on:{"click":function($event){return _vm.deleteThreshold($index)}}},[_c('octo-icon',{attrs:{"icon":"wrong"}})],1)],1)],1)}}])})],1)],2),_c('modal',{attrs:{"modalClasses":"modal-sm","bodyClasses":"px-2","centered":"","show":_vm.showModal},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('h5',{staticClass:"modal-title text-uppercase",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.modalTitle))]),_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.type'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1"},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"placeholder":_vm.$t('fields.choose')},model:{value:(_vm.currentThreshold.type),callback:function ($$v) {_vm.$set(_vm.currentThreshold, "type", $$v)},expression:"currentThreshold.type"}},_vm._l(([
                { value: 'PT', label: _vm.$t('callcenter.part-time')},
                { value: 'FT', label: _vm.$t('callcenter.full-time')} ]),function(option){return _c('el-option',{key:option.value,staticClass:"select-default text-uppercase",attrs:{"value":option.value,"label":option.label}})}),1)],1)],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('callcenter.threshold'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0",attrs:{"required":"","type":"text"},model:{value:(_vm.currentThreshold.threshold),callback:function ($$v) {_vm.$set(_vm.currentThreshold, "threshold", $$v)},expression:"currentThreshold.threshold"}})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('callcenter.value_over_threshold'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0",attrs:{"required":"","type":"text"},model:{value:(_vm.currentThreshold.value),callback:function ($$v) {_vm.$set(_vm.currentThreshold, "value", $$v)},expression:"currentThreshold.value"}})],1)],2)],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"link":""},on:{"click":function($event){return _vm.saveCurrentThreshold()}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }