<template>
  <div>
    <octo-table
      :show-id="false"
      :action="endpoints.DATATABLES.zohoReports"
      :fields="fields"
      :filters="filters"
      :name="name"
      @onResetFilters="filters = {}"
    >


      <template v-slot:status="data">
        <badge :type="getZohoReportStatusColor(data.row.status)">
          {{ data.row.status }}
        </badge>
      </template>

      <template v-slot:path="data">
        <div class="small text-truncate">{{ data.row.path }}</div>
      </template>

      <template v-slot:created_at="data">
        {{ data.row.created_at | datetime }}
      </template>

      <template v-slot:method="data">
        <badge :type="getZohoReportMethodColor(data.row.method)">
          {{ data.row.method }}
        </badge>
      </template>

      <template v-slot:action="data">
        <base-button link icon class="mx-1" size="sm" @click="openZohoModal(data.row)">
          <octo-icon icon="right-arrow"/>
        </base-button>
      </template>

    </octo-table>

    <modal bodyClasses="p-2" modalClasses="modal-lg" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.info') }}</h5>
      <ul class="list-group list-group-flush">
        <list-group-item-component :value="zohoLogSelected.payload" label="payload" :label-width="150"/>
        <list-group-item-component label="status">
          <template slot="value">
            <badge :type="getZohoReportStatusColor(zohoLogSelected.status)">
              {{zohoLogSelected.status}}
            </badge>
          </template>
        </list-group-item-component>
        <list-group-item-component label="path" :value="zohoLogSelected.path"/>
        <list-group-item-component :value="zohoLogSelected.response" label="response" :label-width="150"/>
        <list-group-item-component :value="zohoLogSelected.messages" label="message" :label-width="150"/>
        <list-group-item-component label="method" :label-width="150">
          <template slot="value">
            <badge :type="getZohoReportMethodColor(zohoLogSelected.method)">
              {{ zohoLogSelected.method }}
            </badge>

          </template>
        </list-group-item-component>
        <list-group-item-component label="created_at" :value="zohoLogSelected.created_at | datetime"/>
      </ul>
    </modal>


  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Badge from "@/components/Badge.vue";
import Modal from "@/components/Modal.vue";
import ZohoLog from "@/models/zohoLogs";
import OctoIcon from "@/components/octo-icon/OctoIcon.vue";
import OctoTable from "@/components/octo-table/OctoTable.vue";

export default {
  name: "ZohoReportDatatable",
  components: {
    OctoTable,
    OctoIcon,
    Modal,
    Badge,
    ListGroupItemComponent,
  },
  data() {
    return {
      name: 'ZohoReportDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'created_at', label: 'date', slot: true, width: 180, fixed: true, align: 'center'},
        {prop: 'path', label: 'path', align: "center", slot: true},
        {prop: 'status', slot: true, label: 'status', width: 100, align: "center"},
        {prop: 'method', slot: true, label: 'method', width: 100, align: "center"},
        {prop: 'action', label: 'action', width: 50, slot: true, align: 'left'},
      ],
      zohoLogSelected: ZohoLog,
      showModal: false
    }
  },
  methods: {


    getZohoReportStatusColor(status) {
      return (String(status)[0] === '2') ? 'success' : 'danger';
    },

    getZohoReportMethodColor(method) {
      switch (method) {
        case 'post':
          return 'info'
          break;

        case 'put':
          return 'warning'
          break

        case 'get':
          return 'success'
          break

        default:
          return 'danger'
      }
    },


    openZohoModal(selectedRow) {
      this.zohoLogSelected = selectedRow;
      this.showModal = true;
    },

  }
}
</script>

<style scoped>

</style>
