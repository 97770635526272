<template>
  <div>
    <card class="shadow" body-classes="standard-card-body">
      <template slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ title }}</h4>
          <base-button link icon size="sm" class="ml-auto text-capitalize" @click="openModal()">
            <octo-icon icon="add"/>
          </base-button>
        </div>
      </template>
      <el-table stripe :data="dealThresholdsLocal">
        <el-table-column align="center" :label="$t('fields.type')">
          <div slot-scope="{ row }">
            {{ row.type }}
          </div>
        </el-table-column>
        <el-table-column align="center" :label="$t('callcenter.threshold')">
          <div slot-scope="{ row }">
            {{ row.threshold }}
          </div>
        </el-table-column>
        <el-table-column align="center" :label="$t('callcenter.value_over_threshold')">
          <div slot-scope="{ row }">
            {{ row.value }}
          </div>
        </el-table-column>
        <el-table-column align="right" width="120px">
          <div slot-scope="{ row, $index }" class="d-flex justify-content-end">
            <el-tooltip content="Edit"
                        :open-delay="300"
                        placement="top">
              <base-button
                class="mx-1"
                @click="openModal($index)"
                link
                size="sm"
                icon
              >
                <octo-icon icon="edit"/>
              </base-button>
            </el-tooltip>

            <el-tooltip content="Delete"
                        :open-delay="300"
                        placement="top">
              <base-button
                class="mx-1"
                @click="deleteThreshold($index)"
                type="danger"
                link
                size="sm"
                icon
              >
                <octo-icon icon="wrong"/>
              </base-button>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </card>

    <modal modalClasses="modal-sm" bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">{{ modalTitle }}</h5>
      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('fields.type')" :label-width="labelWidth">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1">
              <el-select
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="currentThreshold.type"
              >
                <el-option
                  v-for="option in [
                  { value: 'PT', label: $t('callcenter.part-time')},
                  { value: 'FT', label: $t('callcenter.full-time')},
                ]"
                  class="select-default text-uppercase"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('callcenter.threshold')" :label-width="labelWidth">
          <template slot="value">
            <base-input
              required
              v-model="currentThreshold.threshold"
              type="text"
              class="mb-0">
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('callcenter.value_over_threshold')" :label-width="labelWidth">
          <template slot="value">
            <base-input
              required
              v-model="currentThreshold.value"
              type="text"
              class="mb-0">
            </base-input>
          </template>
        </list-group-item-component>
      </ul>

      <template slot="footer">
        <base-button link @click="saveCurrentThreshold()">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Switch, Table, TableColumn, Select, Option} from "element-ui";
import Modal from "@/components/Modal";

const ThresholdModel = {
  type: '',
  threshold: 0,
  value: 0,
};

export default {
  name: "CallCenterSettingAwardDealThresholdsCard",
  components: {
    Modal,
    OctoIcon,
    ListGroupItemComponent,
    [Switch.name]: Switch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    dealThresholds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      labelWidth: 140,
      showModal: false,
      modalTitle: '',
      dealThresholdsLocal: [],
      currentThreshold: this.$_.cloneDeep(ThresholdModel),
      currentThresholdIndex: null
    }
  },
  beforeMount() {
    this.dealThresholdsLocal = this.$_.cloneDeep(this.dealThresholds) || [];
  },
  methods: {
    getThresholds() {
      return this.dealThresholdsLocal;
    },

    openModal: function (index) {
      const target = this.dealThresholdsLocal?.[index];

      this.modalTitle = !index && index !== 0
        ? this.$t('callcenter.new_threshold')
        : this.$t('callcenter.edit_threshold');

      this.currentThreshold = this.$_.cloneDeep(target || ThresholdModel);
      this.currentThresholdIndex = index;

      this.showModal = true;
    },

    deleteThreshold: function (index) {
      this.dealThresholdsLocal.splice(index, 1)
    },

    saveCurrentThreshold: function () {
      if (this.currentThresholdIndex || this.currentThresholdIndex === 0) {
        this.$set(
          this.dealThresholdsLocal,
          this.currentThresholdIndex,
          this.$_.cloneDeep(this.currentThreshold)
        );
      } else {
        this.dealThresholdsLocal.push(this.$_.cloneDeep(this.currentThreshold))
      }

      this.currentThreshold = this.$_.cloneDeep(ThresholdModel);
      this.showModal = false;
    },
  }
}
</script>

<style scoped>

</style>
