const LeadStatuses = {
  free: {
    value: 'free',
    color: 'success',
    callCenterChoosable: false,
    substatuses: {}
  },
  invalid: {
    value: 'invalid',
    color: 'danger',
    callCenterChoosable: true,
    substatuses: {
      number_non_existent: {value: 'number_non_existent'},
      number_wrong: {value: 'number_wrong'},
      call_not_started: {value: 'call_not_started'},
      wrong_course: {value: 'wrong_course'},
      untraceable: {value: 'untraceable'},
      call_rejected: {value: 'call_rejected'},
      already_customer: {value: 'already_customer'},
      advice_already_made: {value: 'advice_already_made'},
      no_request_submitted: {value: 'no_request_submitted'},
      job_offer: {value: 'job_offer'},
      info_only_by_email: {value: 'info_only_by_email'},
      no_speak_italian: {value: 'no_speak_italian'},
      request_made_by_other_person: {value: 'request_made_by_other_person'},
      searches_free_course: {value: 'searches_free_course'},
      duplicated_lead: {value: 'duplicated_lead'},
      younger_lead: {value: 'younger_lead'},
    }
  },
  not_interested: {
    value: 'not_interested',
    color: 'warning',
    callCenterChoosable: true,
    substatuses: {
      change_idea: {value: 'change_idea'},
      work_found: {value: 'work_found'},
      choosen_other_school: {value: 'choosen_other_school'},
      economic_problems: {value: 'economic_problems'},
      just_curiosity: {value: 'just_curiosity'},
      info_about_future_course: {value: 'info_about_future_course'},
      no_longer_responds_after_full_call: {value: 'no_longer_responds_after_full_call'},
      confused_ideas: {value: 'confused_ideas'},
      classroom_course_only: {value: 'classroom_course_only'},
      no_longer_responds_after_first_contact: {value: 'no_longer_responds_after_first_contact'},
      searches_full_immersion_course: {value: 'searches_full_immersion_course'},
      not_available_at_moment: {value: 'not_available_at_moment'},
    }
  },
  processing: {
    value: 'processing',
    color: 'info',
    callCenterChoosable: true,
    substatuses: {
      not_answer: {value: 'not_answer'},
      unattainable: {value: 'unattainable'},
      busy: {value: 'busy'},
      private_recall: {value: 'private_recall'},
      public_recall: {value: 'public_recall'},
    }
  },
  promoted: {
    value: 'promoted',
    color: 'primary',
    callCenterChoosable: true,
    substatuses: {
      appointment_confirmed: {value: 'appointment_confirmed'}
    }
  },
  not_confirmed: {
    value: 'not_confirmed',
    color: 'default',
    callCenterChoosable: false,
    substatuses: {}
  }
};

export default LeadStatuses
