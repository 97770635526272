<template>
  <card class="shadow" body-classes="standard-card-body">
    <template slot="header">
      <h4 class="card-title">
        {{ $t('callcenter.deadline_settings') }}
      </h4>
    </template>
    <ValidationObserver ref="CallCenterSettingDeadlinesForm">
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="`${$t('callcenter.deadline_if_busy')} (${$t('common.minutes')})`">
          <template slot="value">
            <ValidationProvider
              name="deadline_if_busy"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="busy"
                type="text"
                class="mb-0"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>
        <list-group-item-component
          :label="`${$t('callcenter.deadline_if_not_answer')} (${$t('common.minutes')})`">
          <template slot="value">
            <ValidationProvider
              name="deadline_if_not_answer"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="notAnswer"
                type="text"
                class="mb-0"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>
        <list-group-item-component
          :label="`${$t('callcenter.deadline_if_unattainable')} (${$t('common.minutes')})`">
          <template slot="value">
            <ValidationProvider
              name="deadline_if_unattainable"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="unattainable"
                type="text"
                class="mb-0"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>
      </ul>
    </ValidationObserver>
  </card>
</template>

<script>
import Slider from "@/components/Slider";
import {mapGetters} from "vuex";
import settingConst from "@/pages/Settings/resources/settingConst";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LeadStatuses from "@/pages/CallCenter/resources/leadStatuses";

export default {
  name: "CallCenterSettingDeadlines",
  components: {
    ListGroupItemComponent,
    Slider
  },
  data() {
    return {
      settingsLocal: {},
      settingConst: settingConst,
      busy: 30,
      notAnswer: 240,
      unattainable: 240
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    })
  },
  beforeMount() {
    this.settingsLocal = this.$_.cloneDeep(
      this.$_.pick(this.settings, [
        settingConst.CALLCENTER_DEADLINES
      ])
    );

    this.busy = this.settingsLocal?.[settingConst.CALLCENTER_DEADLINES]
      ?.[LeadStatuses.processing.substatuses.busy.value]?.minutes || 30;
    this.notAnswer = this.settingsLocal?.[settingConst.CALLCENTER_DEADLINES]
      ?.[LeadStatuses.processing.substatuses.not_answer.value]?.minutes || 240;
    this.unattainable = this.settingsLocal?.[settingConst.CALLCENTER_DEADLINES]
      ?.[LeadStatuses.processing.substatuses.unattainable.value]?.minutes || 240;

  },
  methods: {
    getSettings: function () {
      return new Promise((resolve, reject) => {
        this.$refs.CallCenterSettingDeadlinesForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(
                [
                  {
                    name: settingConst.CALLCENTER_DEADLINES,
                    value: {
                      [LeadStatuses.processing.substatuses.busy.value]: {minutes: this.busy},
                      [LeadStatuses.processing.substatuses.not_answer.value]: {minutes: this.notAnswer},
                      [LeadStatuses.processing.substatuses.unattainable.value]: {minutes: this.unattainable}
                    }
                  },
                ]
              );
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped>

</style>
