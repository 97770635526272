<template>
  <card class="shadow" body-classes="standard-card-body">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('callcenter.callcenter_agenda') }}</h4>
    </div>
    <ul class="list-group list-group-flush">
      <list-group-item-component class="border-bottom-0"
        :value="settingsLocal[settingConst.CALLCENTER_CALENDAR_DAY] | number(0)"
        :label="$t('callcenter.calendar_day')"/>
      <li class="list-group-item bg-transparent pt-1">
        <slider class="mt-3"
                :range="{min: 0, max:15}"
                :options="{step: 1}"
                v-model="settingsLocal[settingConst.CALLCENTER_CALENDAR_DAY]"></slider>
      </li>
      <list-group-item-component class="border-bottom-0"
           :value="`${$options.filters.number(hourRange[0], 0)} - ${$options.filters.number(hourRange[1], 0)}`"
           :label="$t('callcenter.calendar_schedule')"/>

      <li class="list-group-item bg-transparent pt-1">
        <slider class="mt-4"
                :connect="true"
                :range="{min: 0, max: 23}"
                :options="{step: 1}"
                v-model="hourRange"/>
      </li>
      <list-group-item-component class="border-bottom-0"
             :value="settingsLocal[settingConst.CALLCENTER_APPOINTMENT_DURATION]  | number(0)"
             :label="`${$t('callcenter.appointment_duration')} (${$t('common.minutes')})`"/>
      <li class="list-group-item bg-transparent pt-1">
        <slider class="mt-4"
                :range="{min: 30, max: 300}"
                :options="{step: 30}"
                v-model="settingsLocal[settingConst.CALLCENTER_APPOINTMENT_DURATION]"></slider>
      </li>
    </ul>
  </card>
</template>

<script>
import Slider from "@/components/Slider";
import {mapGetters} from "vuex";
import settingConst from "@/pages/Settings/resources/settingConst";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "CallCenterSettingCalendar",
  components: {
    ListGroupItemComponent,
    Slider
  },
  data() {
    return {
      settingsLocal: {},
      hourRange: [],
      settingConst: settingConst
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    })
  },
  beforeMount() {
    this.settingsLocal = this.$_.cloneDeep(
      this.$_.pick(this.settings, [
        settingConst.CALLCENTER_CALENDAR_START,
        settingConst.CALLCENTER_CALENDAR_END,
        settingConst.CALLCENTER_APPOINTMENT_DURATION,
        settingConst.CALLCENTER_CALENDAR_DAY,
      ])
    );

    this.hourRange = [
      this.settingsLocal?.[settingConst.CALLCENTER_CALENDAR_START] || 9,
      this.settingsLocal?.[settingConst.CALLCENTER_CALENDAR_END] || 19
    ];
  },
  methods: {
    getSettings: function () {
      return [
        {
          name: settingConst.CALLCENTER_CALENDAR_DAY,
          value: Number(this.settingsLocal?.[settingConst.CALLCENTER_CALENDAR_DAY] || 3)
        },
        {
          name: settingConst.CALLCENTER_CALENDAR_START,
          value: Number(this.hourRange[0])
        },
        {
          name: settingConst.CALLCENTER_CALENDAR_END,
          value: Number(this.hourRange[1])
        },
        {
          name: settingConst.CALLCENTER_APPOINTMENT_DURATION,
          value: Number(this.settingsLocal?.[settingConst.CALLCENTER_APPOINTMENT_DURATION] || 90)
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
