<template>
  <card class="shadow" body-classes="standard-card-body">
    <template slot="header">
      <h4 class="card-title">
        {{ $t('callcenter.deadline_settings') }}
      </h4>
    </template>
    <ValidationObserver ref="zohoForm">
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="$t('common.client_id')">
          <template slot="value">
            <ValidationProvider
              name="client_id"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="localZohoSecret.client_id"
                type="text"
                class="mb-0"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>

        <list-group-item-component
          :label="$t('common.client_secret')">
          <template slot="value">
            <ValidationProvider
              name="client_secret"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="localZohoSecret.client_secret"
                type="text"
                class="mb-0"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>

        <list-group-item-component
          :label="$t('common.refresh_token')"
          :value="localZohoSecret.refresh_token"
        >
        </list-group-item-component>

        <list-group-item-component
          :label="$t('common.auth_token')">
          <template slot="value">
            <ValidationProvider
              name="auth_code"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="auth_code"
                type="text"
                class="mb-0 "
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>
      </ul>
    </ValidationObserver>
  </card>

</template>

<script>
import {Switch, Select, Option} from "element-ui";
import ListGroupItemComponent from "@/components/ListGroupItemComponent.vue";
import LabelThemeComponent from "@/components/LabelThemeComponent.vue";
import BaseTextArea from "@/components/Inputs/BaseTextArea.vue";
import ZohoSecrets from "@/models/zohoSecrets";
import _ from "lodash";

export default {
  name: "ZohoFormCard",
  components: {
    BaseTextArea,
    LabelThemeComponent,
    ListGroupItemComponent,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      labelWidth: 150,
      localZohoSecret: this.$_.cloneDeep(ZohoSecrets),
      auth_code: ''
    }
  },
  props: {
    zohoSecrets: {
      type: Object,
      default: () => _.cloneDeep(ZohoSecrets)
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.zohoForm.validate()
          .then((success) => {
            if (success) {
              resolve({
                'client_id': this.localZohoSecret.client_id,
                'client_secret': this.localZohoSecret.client_secret,
                'refresh_token': this.localZohoSecret.refresh_token,
                'auth_code': this.auth_code
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mounted() {
    this.localZohoSecret = this.$_.cloneDeep(this.zohoSecrets)
  }
}
</script>

<style scoped>

</style>
