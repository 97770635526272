<template>
  <div>
    <card class="shadow" body-classes="standard-card-body">
      <template slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('callcenter.production_awards_appointment') }}</h4>
          <base-button
            link
            icon
            size="sm"
            class="ml-auto text-capitalize"
            @click="openModal()"
          >
            <octo-icon icon="add"/>
          </base-button>
        </div>
      </template>
      <el-table stripe :data="appointmentAwardList">
        <el-table-column align="left" :label="$t('callcenter.profile')">
          <div slot-scope="{ row }">
            {{ row.profile }}
          </div>
        </el-table-column>
        <el-table-column align="center" :label="$t('fields.type')">
          <div slot-scope="{ row }">
            {{ row.type }}
          </div>
        </el-table-column>
        <el-table-column align="center" :label="$t('callcenter.threshold')">
          <div slot-scope="{ row }">
            {{ row.threshold }}
          </div>
        </el-table-column>
        <el-table-column align="center" :label="$t('callcenter.value_over_threshold')">
          <div slot-scope="{ row }">
            {{ row.value }}
          </div>
        </el-table-column>
        <el-table-column align="right" width="120px">
          <div slot-scope="{ row, $index }" class="d-flex justify-content-end">
            <el-tooltip content="Edit"
                        :open-delay="300"
                        placement="top">
              <base-button
                class="mx-1"
                @click="openModal($index)"
                link
                size="sm"
                icon
              >
                <octo-icon icon="edit"/>
              </base-button>
            </el-tooltip>

            <el-tooltip content="Delete"
                        :open-delay="300"
                        placement="top">
              <base-button
                class="mx-1"
                @click="deleteProfile($index)"
                type="danger"
                link
                size="sm"
                icon
              >
                <octo-icon icon="wrong"/>
              </base-button>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </card>

    <card class="shadow" body-classes="standard-card-body">
      <template slot="header">
        <h4 class="card-title">
          {{ $t('callcenter.production_awards_deal') }}
        </h4>
      </template>
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="`${$t('callcenter.awards_deal_operator')} (${$t('common.euro')})`">
          <template slot="value">

            <base-input
              v-model="settingsLocal[settingConst.CALLCENTER_AWARD_DEAL_OPERATOR]"
              type="text"
              class="mb-0"/>
          </template>
        </list-group-item-component>
        <list-group-item-component
          :label="`${$t('callcenter.awards_deal_manager')} (${$t('common.euro')})`">
          <template slot="value">
            <base-input
              v-model="settingsLocal[settingConst.CALLCENTER_AWARD_DEAL_MANAGER]"
              type="text"
              class="mb-0"/>
          </template>
        </list-group-item-component>
      </ul>
    </card>

    <modal modalClasses="modal-sm" bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ modalTitle }}
      </h5>

      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('callcenter.profile')" :label-width="labelWidth">
          <template slot="value">
            <base-input
              required
              v-model="currentProfileAppointmentAward.profile"
              type="text"
              class="mb-0">
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.type')" :label-width="labelWidth">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1">
              <el-select
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="currentProfileAppointmentAward.type"
              >
                <el-option
                  v-for="option in [
                  { value: 'PT', label: $t('callcenter.part-time')},
                  { value: 'FT', label: $t('callcenter.full-time')},
                ]"
                  class="select-default text-uppercase"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('callcenter.threshold')" :label-width="labelWidth">
          <template slot="value">

            <base-input
              required
              v-model="currentProfileAppointmentAward.threshold"
              type="text"
              class="mb-0">
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('callcenter.value_over_threshold')" :label-width="labelWidth">
          <template slot="value">

            <base-input
              required
              v-model="currentProfileAppointmentAward.value"
              type="text"
              class="mb-0">
            </base-input>
          </template>
        </list-group-item-component>
      </ul>

      <template slot="footer">
        <base-button link @click="saveCurrentProfile">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>

  </div>

</template>

<script>
import Slider from "@/components/Slider";
import {mapGetters} from "vuex";
import settingConst from "@/pages/Settings/resources/settingConst";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Option, Select, Table, TableColumn} from "element-ui";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";

const ProfileAppointmentAward = {
  profile: '',
  type: '',
  threshold: 0,
  value: 0,
};

export default {
  name: "CallCenterSettingAwards",
  components: {
    OctoIcon,
    ListGroupItemComponent,
    Slider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    Modal
  },
  data() {
    return {
      labelWidth: 250,
      settingsLocal: {},
      settingConst: settingConst,
      data: [],
      modalTitle: this.$t('callcenter.new_profile'),
      showModal: false,
      currentProfileAppointmentAward: this.$_.cloneDeep(ProfileAppointmentAward),
      currentProfileAppointmentAwardIndex: null
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),
    appointmentAwardList() {
      return this.$_.orderBy(this.settingsLocal?.[settingConst.CALLCENTER_AWARD_APPOINTMENT], 'profile') || []
    },

  },
  beforeMount() {
    this.settingsLocal = this.$_.cloneDeep(
      this.$_.pick(this.settings, [
        settingConst.CALLCENTER_AWARD_APPOINTMENT,
        settingConst.CALLCENTER_AWARD_DEAL_OPERATOR,
        settingConst.CALLCENTER_AWARD_DEAL_MANAGER,
      ])
    );

  },
  methods: {
    getSettings: function () {
      return [
        {
          name: settingConst.CALLCENTER_AWARD_APPOINTMENT,
          value: this.settingsLocal?.[settingConst.CALLCENTER_AWARD_APPOINTMENT] || []
        },
        {
          name: settingConst.CALLCENTER_AWARD_DEAL_OPERATOR,
          value: Number(this.settingsLocal?.[settingConst.CALLCENTER_AWARD_DEAL_OPERATOR] || 0)
        },
        {
          name: settingConst.CALLCENTER_AWARD_DEAL_MANAGER,
          value: Number(this.settingsLocal?.[settingConst.CALLCENTER_AWARD_DEAL_MANAGER] || 0)
        },
      ]
    },

    openModal: function (index) {
      const target = this.settingsLocal[settingConst.CALLCENTER_AWARD_APPOINTMENT]?.[index];

      this.modalTitle = !index
        ? this.$t('callcenter.new_profile')
        : target?.profile;

      this.currentProfileAppointmentAward = this.$_.cloneDeep(target || ProfileAppointmentAward);
      this.currentProfileAppointmentAwardIndex = index;

      this.showModal = true;
    },

    deleteProfile: function (index) {
      this.settingsLocal[settingConst.CALLCENTER_AWARD_APPOINTMENT].splice(index, 1)
    },

    saveCurrentProfile: function () {
      if(!this.settingsLocal[settingConst.CALLCENTER_AWARD_APPOINTMENT]) {
        this.settingsLocal[settingConst.CALLCENTER_AWARD_APPOINTMENT] = [];
      }
      if (this.currentProfileAppointmentAwardIndex || this.currentProfileAppointmentAwardIndex === 0) {
        this.$set(
          this.settingsLocal[settingConst.CALLCENTER_AWARD_APPOINTMENT],
          this.currentProfileAppointmentAwardIndex,
          this.$_.cloneDeep(this.currentProfileAppointmentAward)
        );
      } else {
        this.settingsLocal[settingConst.CALLCENTER_AWARD_APPOINTMENT]
          .push(this.$_.cloneDeep(this.currentProfileAppointmentAward))
      }

      this.currentProfileAppointmentAward = this.$_.cloneDeep(ProfileAppointmentAward);
      this.showModal = false;
    }
  }
}
</script>

<style scoped>

</style>
