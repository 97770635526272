<template>
  <div class="row">
    <div class="col-12">
      <call-center-setting-award-deal-thresholds-card
        ref="dealThresholdsPersonalCard"
        :deal-thresholds="dealThresholdsPersonal"
        :title="$t('callcenter.thresholds') + ' [' + $t('callcenter.personal') + ']'"
      />
    </div>
    <div class="col-12">
      <call-center-setting-award-deal-thresholds-card
        ref="dealThresholdsGroupCard"
        :deal-thresholds="dealThresholdsGroup"
        :title="$t('callcenter.thresholds') + ' [' + $t('callcenter.group') + ']'"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import settingConst from "@/pages/Settings/resources/settingConst";
import CallCenterSettingAwardDealThresholdsCard from "./CallCenterSettingAwardDealThresholdsCard";

export default {
  name: "CallCenterSettingAwardDealThresholds",
  components: {
    CallCenterSettingAwardDealThresholdsCard,
  },
  data() {
    return {
      settingConst: settingConst,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),

    dealThresholdsPersonal() {
      return this.$_.cloneDeep(this.settings[settingConst.CALLCENTER_AWARD_DEAL_THRESHOLDS_PERSONAL]) || [];
    },

    dealThresholdsGroup() {
      return this.$_.cloneDeep(this.settings[settingConst.CALLCENTER_AWARD_DEAL_THRESHOLDS_GROUP]) || [];
    }
  },
  methods: {
    getSettings() {
      return [
        {
          name: settingConst.CALLCENTER_AWARD_DEAL_THRESHOLDS_PERSONAL,
          value: this.$refs.dealThresholdsPersonalCard.getThresholds()
        },
        {
          name: settingConst.CALLCENTER_AWARD_DEAL_THRESHOLDS_GROUP,
          value: this.$refs.dealThresholdsGroupCard.getThresholds()
        },
      ];
    },
  }
}
</script>

<style scoped>

</style>
