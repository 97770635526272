<template>
  <div>
    <octo-table
      :show-id="false"
      :action="endpoints.DATATABLES.emailTemplates"
      :fields="fields"
      :filters="filters"
      :name="name"
      :key="`email-template-datatable-${datatableKey}`"
      @onResetFilters="filters = {}"
    >
      <template v-slot:name="data">
        <div class="text-truncate">{{ $t('emails.templates.' + data.row.name) }}</div>
      </template>

      <template v-slot:reply_to="data">
        <div class="small text-truncate">{{ data.row | optional('reply_to') }}</div>
      </template>


      <template v-slot:subject="data">
        <div class="small text-truncate">{{ data.row | optional('subject') }}</div>
      </template>

      <template v-slot:email_labels="data">
        <div class="small text-truncate">{{ data.row | optional('email_labels') }}</div>
      </template>

      <template v-slot:description="data">
        <div class="small text-truncate">{{ data.row | optional('description') }}</div>
      </template>

      <template v-slot:attachments="data">
        {{ data.row.attachments ? data.row.attachments.length : 0 }}
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <base-button link icon class="mx-1" size="sm" @click="openTemplateModal(data.row)">
            <octo-icon icon="edit"/>
          </base-button>
        </div>
      </template>
    </octo-table>

    <modal bodyClasses="p-2" modalClasses="modal-lg" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.edit') }}</h5>
      <ul class="list-group list-group-flush">
        <list-group-title-section-component :label="$t('common.details')"/>
        <list-group-item-component :label="$t('common.reply_to')" :label-width="140">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1" type="text" v-model="selectedEmailTemplate.reply_to"/>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('datatable.subject')" :label-width="140">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1" type="text" v-model="selectedEmailTemplate.subject"/>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.email_label')" :label-width="140">
          <template slot="value">
              <base-input class="mb-0 flex-grow-1">
                <el-select
                  multiple
                  class="select-default text-uppercase"
                  :placeholder="$t('fields.choose')"
                  v-model="selectedEmailTemplate.email_labels"
                >
                  <el-option
                    v-for="label in DealEmailLabel"
                    class="select-default text-uppercase"
                    :value="label.value"
                    :label="label.label"
                  />
                </el-select>
              </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.description')" :label-width="140">
          <template slot="value">
            <base-text-area class="mb-0 flex-grow-1" :rows="2" v-model="selectedEmailTemplate.description"/>
          </template>
        </list-group-item-component>
        <list-group-title-section-component :label="$t('common.attachments')"/>
        <li class="list-group-item d-flex justify-content-center p-0">
          <octo-upload-cloud-email-template
            :presigned-url-action="endpoints.EMAIL_TEMPLATES_PRESIGNED_URL_V2.replace('{id}', selectedEmailTemplate.id)"
            @onSuccessUpload="successUpload"
          />
        </li>
        <li class="list-group-item p-0">
          <email-template-attachments
            :email-template="selectedEmailTemplate"
            :key="`attachments-${modalKey}`"
            v-if="selectedEmailTemplate.attachments && selectedEmailTemplate.attachments.length > 0"
          />
        </li>
      </ul>
      <template slot="footer">
        <base-button link class="text-uppercase" @click="updateEmailTemplate()">
          {{ $t('common.update') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import EmailTemplate from "@/models/emailTemplate";
import EmailTemplateAttachments from "@/pages/Settings/components/EmailTemplateAttachments";
import OctoUploadCloudEmailTemplate from "@/components/octo-upload-cloud/OctoUploadCloudEmailTemplate";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import DealEmailLabel from "@/pages/Deals/resources/dealEmailLabel";
import {Option, Select} from "element-ui";

export default {
  name: "EmailTemplateDatatable",
  components: {
    BaseTextArea,
    ListGroupTitleSectionComponent,
    ListGroupItemComponent,
    OctoUploadCloudEmailTemplate,
    EmailTemplateAttachments,
    Modal,
    OctoIcon,
    OctoTable,
    [Option.name]: Option,
    [Select.name]: Select
  },
  computed: {
    DealEmailLabel() {
      return DealEmailLabel
    }
  },
  data() {
    return {
      emailLocal: '',
      name: 'EmailTemplateDatatable',
      endpoints: endpoints,
      filters: {},
      selectedEmailTemplate: this.$_.cloneDeep(EmailTemplate),
      fields: [
        {prop: 'name', label: 'name', width: 250, fixed: true, slot: true},
        {prop: 'reply_to', label: 'reply_to', width: 250, slot: true },
        {prop: 'subject', label: 'subject', width: 125, slot: true},
        {prop: 'email_labels', label: 'email_labels', width: 200, slot: true},
        {prop: 'description', label: 'description', minWidth: 250, slot: true},
        {prop: 'attachments', label: 'attachments', slot: true, width: 100, align: 'center'},
        {prop: 'actions', label: 'actions', width: 60, slot: true, fixed: 'right'}
      ],
      modalKey: 0,
      datatableKey: 0,
      showModal: false,
    }
  },

  methods: {
    successUpload: function (attachment) {
      this.selectedEmailTemplate.attachments.unshift(attachment);
    },

    openTemplateModal(emailTemplate) {
      if (!emailTemplate.attachments) {
        emailTemplate.attachments = [];
      }

      this.selectedEmailTemplate = this.$_.cloneDeep(emailTemplate);
      this.modalKey++;
      this.showModal = true;
    },

    updateEmailTemplate() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.EMAIL_TEMPLATES_UPDATE_V2.replace('{id}', this.selectedEmailTemplate.id),
        {
          attachments: this.selectedEmailTemplate.attachments,
          reply_to: this.selectedEmailTemplate.reply_to,
          subject: this.selectedEmailTemplate.subject,
          email_labels: this.selectedEmailTemplate.email_labels,
          description: this.selectedEmailTemplate.description,
        }
      )
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
          this.datatableKey++;
          this.showModal = false;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    }
  }


}
</script>

<style scoped>

</style>
