<template>
  <el-table stripe :data="emailTemplate.attachments">
    <el-table-column align="left" :label="$t('common.attachment_name')">
      <div slot-scope="{ row }">
        <base-input class="mb-0" type="text" v-model="row.name"/>
      </div>
    </el-table-column>
    <el-table-column align="right" width="150">
      <div slot-scope="{ row }" class="text-truncate">
        <a :href="row.url" target="_blank">
          <base-button link icon class="mx-1" size="sm">
            <octo-icon icon="download"/>
          </base-button>
        </a>
        <base-button
          link
          icon
          class="mx-1"
          size="sm"
          confirm-required
          :confirm-text="$t('confirm.delete_document')"
          @click="deleteAttachment(row.name)"
        >
          <octo-icon icon="wrong"/>
        </base-button>
      </div>
    </el-table-column>
    <template slot="empty">
      {{ $t('datatable.no_result') }}
    </template>
  </el-table>
</template>

<script>
import {permissionService} from "@/util/permission-service";
import {Table, TableColumn} from "element-ui";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import EmailTemplate from "@/models/emailTemplate";

export default {
  name: "EmailTemplateAttachments",
  components: {OctoIcon, [Table.name]: Table, [TableColumn.name]: TableColumn},
  data() {
    return {
      permissionService: permissionService
    }
  },
  props: {
    emailTemplate: {
      type: Object,
      default: () => _.cloneDeep(EmailTemplate)
    }
  },
  methods: {
    deleteAttachment(name) {
      this.emailTemplate.attachments.splice(this.$_.findIndex(this.emailTemplate.attachments, item => item.name === name), 1);
    }
  }
}
</script>

<style scoped>

</style>
