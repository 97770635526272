var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"shadow",attrs:{"body-classes":"standard-card-body"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('callcenter.deadline_settings'))+" ")])]),_c('ValidationObserver',{ref:"CallCenterSettingDeadlinesForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":((_vm.$t('callcenter.deadline_if_busy')) + " (" + (_vm.$t('common.minutes')) + ")")}},[_c('template',{slot:"value"},[_c('ValidationProvider',{attrs:{"name":"deadline_if_busy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.busy),callback:function ($$v) {_vm.busy=$$v},expression:"busy"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":((_vm.$t('callcenter.deadline_if_not_answer')) + " (" + (_vm.$t('common.minutes')) + ")")}},[_c('template',{slot:"value"},[_c('ValidationProvider',{attrs:{"name":"deadline_if_not_answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.notAnswer),callback:function ($$v) {_vm.notAnswer=$$v},expression:"notAnswer"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":((_vm.$t('callcenter.deadline_if_unattainable')) + " (" + (_vm.$t('common.minutes')) + ")")}},[_c('template',{slot:"value"},[_c('ValidationProvider',{attrs:{"name":"deadline_if_unattainable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.unattainable),callback:function ($$v) {_vm.unattainable=$$v},expression:"unattainable"}})]}}])})],1)],2)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }