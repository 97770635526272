<template>
  <el-upload
    ref="upload"
    :show-file-list="false"
    :action="action"
    :on-success="handleSuccess"
    :before-upload="handleBeforeUpload"
    :http-request="httpRequest"
    :file-list="fileList"
    multiple
  >
    <base-button class="text-uppercase" link>
      <div class="el-upload__text">
        <octo-icon icon="upload" class="mr-1"/>{{ $t('common.upload') }}
      </div>
    </base-button>
  </el-upload>
</template>

<script>
import {Option, Select, Upload} from 'element-ui';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoUploadCloudMixin from "./OctoUploadCloudMixin";

export default {
  name: "OctoUploadCloudEmailTemplate",
  components: {
    OctoIcon,
    [Upload.name]: Upload,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  mixins: [OctoUploadCloudMixin],
  methods: {
    handleSuccess(response, file, fileList) {
      const url = file?.response?.config?.url || '';

      this.$emit('onSuccessUpload', {
        name: file.name,
        url: url.substring(0, url.indexOf('?'))
      });

      this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
    },
  }
}
</script>

<style scoped>

</style>
