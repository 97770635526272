const ZohoLog = {
  path: '',
  payload: '',
  response: '',
  status: '',
  created_at: '',
  updated_at: '',
};

export default ZohoLog
