import axios from "axios";

const OctoUploadCloudMixin = {
  props: {
    presignedUrlAction: {
      type: String,
      default: ""
    },
    storeUrlAction: {
      type: String,
      default: ""
    },
    tagList: {
      type: Array,
      default: () => ['other']
    },
    showFullLoading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      fileList: [],
      action: "",
      fileTag: '',
      fileToUpload: {
        filename: '',
        size: 0,
        mime_type: '',
        tag: 'other'
      },
    }
  },
  methods: {
    handleSuccess() {
      if (this.showFullLoading) {
        this.$fullLoading.show();
      }

      this.$api.post(this.storeUrlAction, this.fileToUpload)
        .then((resp) => {
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
          this.$refs.upload.clearFiles();
          const dataToEmit = resp?.data?.data || {};
          dataToEmit.tag = this.$_.clone(this.fileToUpload.tag);
          this.$emit('onSuccessUpload', dataToEmit)
          this.fileToUpload = {
            filename: '',
            size: 0,
            mime_type: '',
            tag: 'other'
          };
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
          this.$refs.upload.clearFiles();
        })
        .finally(() => {
          if (this.showFullLoading) {
            this.$fullLoading.hide();
          }
        })
    },

    async handleBeforeUpload(file) {
      const response = await this.$api.post(
        this.presignedUrlAction,
        {filename: file.name, mime_type: file.type}
      );
      this.fileToUpload.filename = response?.data?.filename || null;
      this.fileToUpload.size = file.size;
      this.fileToUpload.mime_type = file.type;
      this.action = response?.data?.presigned_url || '';
      return true;
    },

    async httpRequest(option) {
      const axiosInstance = axios.create({
        headers: {
          'Content-Type': option.file.type,
          'spa-version': process.env.VUE_APP_VERSION
        }
      });

      try {
        return await axiosInstance.put(option.action, option.file)
      } catch (e) {
        console.log(e);
      }
    },
    startUpload() {
      this.$refs.upload.submit();
    },
  }
}

export default OctoUploadCloudMixin;
