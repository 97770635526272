<template>
  <card class="shadow" body-classes="standard-card-body">
    <template slot="header" v-if="title">
      <h4 class="card-title text-capitalize">{{ title }}</h4>
    </template>
    <ul class="list-group list-group-flush">
      <list-group-item-component
        v-bind:key="`list-keys-${index}`"
        v-for="(user, index) in localUsers"
        :label="user.firstname + ' ' + user.lastname"
      >
        <template slot="value">
          <base-input class="mb-0">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.profile')"
              v-model="user.profile"
            >
              <el-option
                v-for="(profile, index) in profileOptions"
                v-bind:key="`user-profile-${index}`"
                class="select-default text-uppercase"
                :value="profile"
                :label="profile"
              />
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>
    </ul>
  </card>
</template>

<script>

import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import settingConst from "../resources/settingConst";
import {Select, Option} from "element-ui";

export default {
  name: "UsersProfiles",
  components: {ListGroupItemComponent, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      localUsers: [],
      settingConst: settingConst
    }
  },
  props: {
    usersList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.localUsers = this.$_.cloneDeep(this.usersList);
  },
  methods: {
    getUsersData: function () {
      return this.localUsers.map((item) => {
        return {user_id: item.id, profile: "" + (item?.profile || '')}
      });
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),

    profileOptions() {
      const profiles = [];

      this.$_.each(this.settings[settingConst.CALLCENTER_AWARD_APPOINTMENT], item => {
        if (!this.$_.find(profiles, profile => item.profile === profile)) {
          profiles.push(item.profile);
        }
      });

      return profiles;
    }
  }
}
</script>

<style scoped>

</style>
