const settingConst = {
  CALLCENTER_CALENDAR_DAY : 'callcenter_calendar_day',
  CALLCENTER_CALENDAR_START : 'callcenter_calendar_start',
  CALLCENTER_CALENDAR_END : 'callcenter_calendar_end',
  CALLCENTER_APPOINTMENT_DURATION : 'callcenter_appointment_duration',
  CALLCENTER_DEADLINES : 'callcenter_deadlines',
  CALLCENTER_AWARD_APPOINTMENT : 'callcenter_award_appointment',
  CALLCENTER_AWARD_DEAL_OPERATOR : 'callcenter_award_deal_operator',
  CALLCENTER_AWARD_DEAL_MANAGER : 'callcenter_award_deal_manager',

  CLICK_ACADEMY_PROVIDER_NAME : 'Click Academy',
  CLICK_ACADEMY_PROVIDER_SLUG : 'click-academy',

  CALLCENTER_AWARD_DEAL_THRESHOLDS_PERSONAL: 'callcenter_award_deal_thresholds_personal',
  CALLCENTER_AWARD_DEAL_THRESHOLDS_GROUP: 'callcenter_award_deal_thresholds_group',

  SALES_THRESHOLDS: 'sales_thresholds',
  PAYMENTS_TYPE: 'payment_type',
  INSTALLMENTS_PLAN: 'installment_plan',

  SETTINGS_ZOHO : 'zoho-secrets'
}

export default settingConst
