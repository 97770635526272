var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"shadow",attrs:{"body-classes":"standard-card-body"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('callcenter.deadline_settings'))+" ")])]),_c('ValidationObserver',{ref:"zohoForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('common.client_id')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{attrs:{"name":"client_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.localZohoSecret.client_id),callback:function ($$v) {_vm.$set(_vm.localZohoSecret, "client_id", $$v)},expression:"localZohoSecret.client_id"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('common.client_secret')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{attrs:{"name":"client_secret","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.localZohoSecret.client_secret),callback:function ($$v) {_vm.$set(_vm.localZohoSecret, "client_secret", $$v)},expression:"localZohoSecret.client_secret"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('common.refresh_token'),"value":_vm.localZohoSecret.refresh_token}}),_c('list-group-item-component',{attrs:{"label":_vm.$t('common.auth_token')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{attrs:{"name":"auth_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0 ",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.auth_code),callback:function ($$v) {_vm.auth_code=$$v},expression:"auth_code"}})]}}])})],1)],2)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }