<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.settings') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="headerButtons"
              @onRefreshToken="refreshToken"
            />
            <zoho-form-card
              :zoho-secrets="zohoSecrets"
              :key="`zohoFormCard-${key}`"
              ref="zohoFormCard"/>


          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.logs') }}</div>
              </div>
            </template>
            <ZohoReportDatatable :key="`zoho-report-datatable-${key}`" />
          </tab-pane>
        </tabs>


      </div>
    </div>
  </div>
</template>

<script>

import EmailTemplateDatatable from "@/pages/Settings/datatables/EmailTemplateDatatable";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters, mapMutations} from "vuex";
import {endpoints} from "@/routes/endpoints";
import ZohoFormCard from "@/pages/Settings/components/ZohoFormCard.vue";
import settingConst from "@/pages/Settings/resources/settingConst";
import ZohoSecrets from "@/models/zohoSecrets";
import CallCenterSettingDeadlines from "@/pages/Settings/components/CallCenterSettingDeadlines.vue";
import CallCenterSettingCalendar from "@/pages/Settings/components/CallCenterSettingCalendar.vue";
import CallCenterSettingAwardDealThresholds from "@/pages/Settings/components/CallCenterSettingAwardDealThresholds.vue";
import UsersProfiles from "@/pages/Settings/components/UsersProfiles.vue";
import CallCenterSettingAwards from "@/pages/Settings/components/CallCenterSettingAwards.vue";
import UsersSalaries from "@/pages/Settings/components/UsersSalaries.vue";
import {TabPane, Tabs} from "@/components";
import ZohoReportDatatable from "@/pages/Deals/datatables/ZohoReportDatatable.vue";
import zohoSecrets from "@/models/zohoSecrets";

export default {
  name: "ZohoPage",
  components: {
    ZohoReportDatatable,
    UsersSalaries, CallCenterSettingAwards, UsersProfiles, CallCenterSettingAwardDealThresholds,
    TabPane,
    Tabs,
    CallCenterSettingCalendar,
    CallCenterSettingDeadlines,
    ZohoFormCard, OctoHeaderButton, EmailTemplateDatatable},
  data() {
    return {
      zohoSecrets: this.$_.cloneDeep(ZohoSecrets),
      authCode: '',
      headerButtons: [{
        label: 'save',
        onClick: 'onRefreshToken',
      }],
      key: 1
    }
  },
  methods: {
    ...mapMutations({
      setSettingsByName: 'common/setSettingsByName'
    }),

    refreshToken: async function () {

      const data = await this.$refs.zohoFormCard.validate();

      const resp = await this.$api.post(endpoints.SETTINGS_ZOHO, data);

      this.setSettingsByName({
        name: settingConst.SETTINGS_ZOHO,
        value: resp.data
      })

      this.zohoSecrets = this.settings[settingConst.SETTINGS_ZOHO]

      this.key++;
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    })
  },
  beforeMount() {
    this.zohoSecrets = this.settings[settingConst.SETTINGS_ZOHO] || {}
    this.key++;
  }
}
</script>

<style scoped>

</style>
